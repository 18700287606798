<template>
  <div class="ancvue-product-detail phone" v-if="!$store.state.isPadHorizontal">
    <!-- 二维码 -->
    <img class="ancvue-qr-code" src="@/assets/imgs/qr-code.png" @click="showQRCode" />
    <!-- 产品详情 -->
    <div class="ancvue-product-info">
      <!-- 左侧 -->
      <div class="left">
        <!-- 描述 -->
        <div class="description">
          如下为建议的试戴片参数，若参数可行，可点击上方二维码，长按并识别二维码后会跳出建议参数和推荐产品，可保存使用
        </div>
        <!-- 球镜、柱镜、轴位 -->
        <div class="product-data">
          <div class="row">
            <div class="cell"></div>
            <div class="cell">球镜</div>
            <div class="cell">柱镜</div>
            <div class="cell">轴位</div>
          </div>
          <div class="divider"></div>
          <div
            class="row"
            v-if="
              (showWitchEye == 'Right' || showWitchEye == 'All') &&
              (whitchEyeHasValue === 'right' || whitchEyeHasValue === 'all')
            "
          >
            <div class="cell">右眼</div>
            <div class="cell number">{{ productInfo.sphereRight }}</div>
            <div class="cell number">{{ productInfo.cylinderRight }}</div>
            <div class="cell number">{{ productInfo.axisRight }}°</div>
          </div>
          <div
            class="divider"
            v-if="
              (showWitchEye == 'Left' || showWitchEye == 'All') &&
              (whitchEyeHasValue === 'left' || whitchEyeHasValue === 'all')
            "
          ></div>
          <div
            class="row"
            v-if="
              (showWitchEye == 'Left' || showWitchEye == 'All') &&
              (whitchEyeHasValue === 'left' || whitchEyeHasValue === 'all')
            "
          >
            <div class="cell">左眼</div>
            <div class="cell number">{{ productInfo.sphereLeft }}</div>
            <div class="cell number">{{ productInfo.cylinderLeft }}</div>
            <div class="cell number">{{ productInfo.axisLeft }}°</div>
          </div>
        </div>
        <!-- 镜片轴位偏转修正按钮 -->
        <div class="fix-button-wrapper">
          <div class="fix-button" ref="clearButtonRef" @click="onFix">
            <span class="fix-button-text" :style="clearButtonStyle"
              >镜片片标偏转修正</span
            >
          </div>
          <!-- 感叹号按钮 -->
          <div class="bottom-info" ref="bottomTooltipRef">
            <img
              src="@/assets/imgs/info.png"
              @click.prevent="onBottomExclamationMarkClick"
            />
            <!-- tooltip -->
            <div class="distance-tooltip" v-if="showBottomTooltip">
              <div class="text">
                十个ACUVUE®散光产品中，有九个会在+/-10度的范围内按预定方向排列。然而，如果试镜片已经转离预期位置，影响了视力，可以通过选择“镜片片标偏转”来计算轴补偿。
              </div>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="right">
        <!-- 产品图片 -->
        <div v-for="(item, index) in productList" :key="index">
          <div class="product-item" v-if="item?.productId">
            <div class="product-bg"><img :src="item.productUrl" /></div>
            <div class="product-name">{{ item.productName }}</div>
          </div>
        </div>
        <!-- 其他可选产品 -->
        <div class="other-products">
          <span @click="chooseOtherProducts">其他可选产品</span>
        </div>
      </div>
    </div>
    <!-- 轴位偏转修正区域 -->
    <div class="ancvue-fix-contianer" v-if="showFix">
      <!-- 标题 -->
      <div class="fix-title">
        <span class="fix-title-text">片标偏转修正</span>
        <span class="fix-star">&nbsp;*</span>
      </div>
      <!-- 文字说明 -->
      <div class="paragraph paragraph-1">
        如果镜片片标偏转超过10度并影响视力表现，请在下方输入偏转数值以及方向，然后点击计算按钮，进行新镜片参数的确认。
      </div>
      <div class="paragraph paragraph-2">
        请注意：经过修正后的新的镜片参数不影响镜片偏转位置显示。
      </div>
      <!-- 分割线 -->
      <div class="fix-divider"></div>
      <!-- 旋转区域 -->
      <div class="fix-adjustment">
        <div class="half" v-if="showWitchEye == 'Right' || showWitchEye == 'All'">
          <div class="text">右眼</div>
          <img
            class="rotate-icon"
            src="@/assets/imgs/rotate-plus.png"
            @click="onRightPlus"
          />
          <div class="rotate-number">
            <img
              class="rotate-number-circle"
              :style="RERStyle"
              src="@/assets/imgs/fix-circle-number.png"
            />
            <div class="number">
              <span>{{ rightAxisDeg }}</span>
              <span>°</span>
            </div>
          </div>
          <img
            class="rotate-icon"
            src="@/assets/imgs/rotate-minus.png"
            @click="onRightMinus"
          />
        </div>
        <div class="half" v-if="showWitchEye == 'Left' || showWitchEye == 'All'">
          <div class="text">左眼</div>
          <img
            class="rotate-icon"
            src="@/assets/imgs/rotate-plus.png"
            @click="onLeftPlus"
          />
          <div class="rotate-number">
            <img
              class="rotate-number-circle"
              :style="LERStyle"
              src="@/assets/imgs/fix-circle-number.png"
            />
            <div class="number">
              <span>{{ leftAxisDeg }}</span>
              <span>°</span>
            </div>
          </div>
          <img
            class="rotate-icon"
            src="@/assets/imgs/rotate-minus.png"
            @click="onLeftMinus"
          />
        </div>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="ancvue-calc-button" v-if="showFix" @click="goToProductDetail">
      <span class="calc-button-text">计算</span>
    </div>
    <div class="ancvue-go-back-button" v-if="!showFix" @click="goBackHome">
      <img class="arrow" src="@/assets/imgs/left-arrow.png" />
      <span ref="backBtnRef" class="go-back-button-text" :style="backBtnStyle"
        >重新选择度数</span
      >
    </div>
  </div>

  <!-- ipad -->
  <div class="ancvue-product-detail pad-horizontal" v-if="$store.state.isPadHorizontal">
    <!-- 二维码 -->
    <img class="ancvue-qr-code" src="@/assets/imgs/qr-code.png" @click="showQRCode" />
    <!-- 描述 -->
    <div class="description">
      如下为建议的试戴片参数，若参数可行，可点击上方二维码，长按并识别二维码后会跳出建议参数和推荐产品，可保存使用
    </div>
    <!-- 产品详情 -->
    <div class="ancvue-product-info">
      <!-- 左侧 -->
      <div class="left">
        <!-- 球镜、柱镜、轴位 -->
        <div class="product-data">
          <div class="row">
            <div class="cell"></div>
            <div class="cell">球镜</div>
            <div class="cell">柱镜</div>
            <div class="cell">轴位</div>
          </div>
          <div class="divider"></div>
          <div class="row" v-if="showWitchEye == 'Right' || showWitchEye == 'All'">
            <div class="cell">右眼</div>
            <div class="cell number">{{ productInfo.sphereRight }}</div>
            <div class="cell number">{{ productInfo.cylinderRight }}</div>
            <div class="cell number">{{ productInfo.axisRight }}°</div>
          </div>
          <div class="row" v-if="showWitchEye == 'Left' || showWitchEye == 'All'">
            <div class="cell">左眼</div>
            <div class="cell number">{{ productInfo.sphereLeft }}</div>
            <div class="cell number">{{ productInfo.cylinderLeft }}</div>
            <div class="cell number">{{ productInfo.axisLeft }}°</div>
          </div>
        </div>
        <!-- 镜片轴位偏转修正按钮 -->
        <div class="fix-button-wrapper">
          <div class="fix-button" ref="clearButtonRef" @click="onFix">
            <span class="fix-button-text" :style="clearButtonStyle"
              >镜片片标偏转修正</span
            >
          </div>
          <!-- 感叹号按钮 -->
          <div class="bottom-info" ref="bottomTooltipRef">
            <img
              src="@/assets/imgs/info.png"
              @click.prevent="onBottomExclamationMarkClick"
            />
            <!-- tooltip -->
            <div class="distance-tooltip" v-if="showBottomTooltip">
              <div class="text">
                十个ACUVUE®散光产品中，有九个会在+/-10度的范围内按预定方向排列。然而，如果试镜片已经转离预期位置，影响了视力，可以通过选择“镜片片标偏转”来计算轴补偿。
              </div>
              <div class="triangle"></div>
            </div>
          </div>
        </div>
        <!-- 其他可选产品 -->
        <div class="other-products">
          <span @click="chooseOtherProducts">其他可选产品</span>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="middle">
        <!-- 产品图片 -->
        <div v-for="(item, index) in productList" :key="index">
          <div class="product-item" v-if="item?.productId">
            <div class="product-bg"><img :src="item.productUrl" /></div>
            <div class="product-name">{{ item.productName }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 轴位偏转修正区域 -->
        <div class="ancvue-fix-contianer" v-if="showFix">
          <!-- 标题 -->
          <div class="fix-title">
            <span class="fix-title-text">片标偏转修正</span>
            <span class="fix-star">&nbsp;*</span>
          </div>
          <!-- 文字说明 -->
          <div class="paragraph paragraph-1">
            如果镜片片标偏转超过10度并影响视力表现，请在下方输入偏转数值以及方向，然后点击计算按钮，进行新镜片参数的确认。
          </div>
          <div class="paragraph paragraph-2">
            请注意：经过修正后的新的镜片参数不影响镜片偏转位置显示。
          </div>
          <!-- 分割线 -->
          <div class="fix-divider"></div>
          <!-- 旋转区域 -->
          <div class="fix-adjustment">
            <div class="half" v-if="showWitchEye == 'Right' || showWitchEye == 'All'">
              <div class="text">右眼</div>
              <div class="half-bottom">
                <img
                  class="rotate-icon"
                  src="@/assets/imgs/rotate-plus.png"
                  @click="onRightPlus"
                />
                <div class="rotate-number">
                  <img
                    class="rotate-number-circle"
                    :style="RERStyle"
                    src="@/assets/imgs/fix-circle-number.png"
                  />
                  <div class="number">
                    <span>{{ rightAxisDeg }}</span>
                    <span>°</span>
                  </div>
                </div>
                <img
                  class="rotate-icon"
                  src="@/assets/imgs/rotate-minus.png"
                  @click="onRightMinus"
                />
              </div>
            </div>
            <div class="half" v-if="showWitchEye == 'Left' || showWitchEye == 'All'">
              <div class="text">左眼</div>
              <div class="half-bottom">
                <img
                  class="rotate-icon"
                  src="@/assets/imgs/rotate-plus.png"
                  @click="onLeftPlus"
                />
                <div class="rotate-number">
                  <img
                    class="rotate-number-circle"
                    :style="LERStyle"
                    src="@/assets/imgs/fix-circle-number.png"
                  />
                  <div class="number">
                    <span>{{ leftAxisDeg }}</span>
                    <span>°</span>
                  </div>
                </div>
                <img
                  class="rotate-icon"
                  src="@/assets/imgs/rotate-minus.png"
                  @click="onLeftMinus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="ancvue-calc-button" v-if="showFix" @click="goToProductDetail">
      <span class="calc-button-text">计算</span>
    </div>
    <div class="ancvue-go-back-button" v-if="!showFix" @click="goBackHome">
      <img class="arrow" src="@/assets/imgs/left-arrow.png" />
      <span ref="backBtnRef" class="go-back-button-text" :style="backBtnStyle"
        >重新选择度数</span
      >
    </div>
  </div>

  <!-- 弹窗 二维码 -->
  <div
    class="dialog"
    :class="$store.state.isPadHorizontal ? 'pad-horizontal' : 'phone'"
    v-show="showQrcodeDialog"
    @click="showQrcodeDialog = false"
  >
    <div class="dialog_box" @click.stop>
      <img src="@/assets/imgs/close_round.png" @click="showQrcodeDialog = false" />
      <!-- 二维码输出容器 -->
      <div id="QRCode"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed, toRaw, nextTick } from "vue";
import dropdown from "@/components/dropdown.vue";
import useClickOutside from "@/hooks/useClickOutside";
import QRCode from "qrcodejs2-fix";
import { frontendurl } from "@/http/baseurl.js";
import { getEvenPx } from "@/js/getEvenPx";

import { useRouter } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({});
const emits = defineEmits([]);
const router = useRouter();

let whitchEyeHasValue = window.localStorage.getItem("whitchEyeHasValue");

// 用户选择的处方数据
const prescription = ref({
  leftSphere: null, // 左眼球镜
  rightSphere: null, // 右眼球镜
  leftCylinder: null, // 左眼柱镜
  rightCylinder: null, // 右眼柱镜
  leftAxis: null,
  rightAxis: null,
});
const productInfo = ref({
  axisLeft: null,
  axisRight: null,
});
const productList = ref([]);
const selectedProductId = ref([]);
const showWitchEye = ref("");

// 从缓存获取已选处方
const obj = JSON.parse(window.sessionStorage.getItem("prescription"));
if (obj) prescription.value = obj;

const leftAxisDeg = ref(0);
const rightAxisDeg = ref(0);
const showFix = ref(false);
const showQrcodeDialog = ref(false);
const bottomTooltipRef = ref(null);
const showBottomTooltip = ref(false);

const clearButtonRef = ref(null);
const backBtnRef = ref(null);

const LERStyle = computed(() => ({
  transform: `rotate(${leftAxisDeg.value}deg)`,
})); // 左眼轴位偏转修正的旋转样式

const RERStyle = computed(() => ({
  transform: `rotate(${rightAxisDeg.value}deg)`,
})); // 右眼轴位偏转修正的旋转样式

// 点击左眼的加号
const onLeftPlus = () => {
  if (leftAxisDeg.value >= 30) return;
  leftAxisDeg.value = leftAxisDeg.value + 1;
};

// 点击左眼的减号
const onLeftMinus = () => {
  if (leftAxisDeg.value <= -30) return;
  leftAxisDeg.value = leftAxisDeg.value - 1;
};

// 点击右眼的加号
const onRightPlus = () => {
  if (rightAxisDeg.value >= 30) return;
  rightAxisDeg.value = rightAxisDeg.value + 1;
};

// 点击右眼的减号
const onRightMinus = () => {
  if (rightAxisDeg.value <= -30) return;
  rightAxisDeg.value = rightAxisDeg.value - 1;
};

// 点击镜片轴位偏转修正按钮，显示修正区域
const onFix = () => (showFix.value = !showFix.value);

// 点击选择其他产品，返回上一页
const chooseOtherProducts = () => {
  window.sessionStorage.setItem("leftFixAxisDeg", leftAxisDeg.value);
  window.sessionStorage.setItem("rightFixAxisDeg", rightAxisDeg.value);
  window.sessionStorage.setItem("productInfo", JSON.stringify([]));
  // window.sessionStorage.setItem("selectedProductId", JSON.stringify([]));
  router.push({ name: "ProductList" });
};

// 点击计算按钮，返回产品列表页
const goToProductDetail = () => {
  window.sessionStorage.setItem("leftFixAxisDeg", leftAxisDeg.value);
  window.sessionStorage.setItem("rightFixAxisDeg", rightAxisDeg.value);
  window.sessionStorage.setItem("productInfo", JSON.stringify([]));
  // window.sessionStorage.setItem("selectedProductId", JSON.stringify([]));
  router.push({ name: "ProductList" });
};

// 点击返回按钮，返回首页
const goBackHome = () => {
  window.sessionStorage.setItem("productInfo", JSON.stringify([]));
  window.sessionStorage.setItem("selectedProductId", JSON.stringify([]));
  router.push({ name: "Home" });
};

// 点击二维码显示弹窗
const showQRCode = () => {
  showQrcodeDialog.value = true;
  const id = JSON.stringify(selectedProductId.value);
  const leftSphere = productInfo.value.sphereLeft;
  const rightSphere = productInfo.value.sphereRight;
  const leftCylinder = productInfo.value.cylinderLeft;
  const rightCylinder = productInfo.value.cylinderRight;
  const axisLeft = productInfo.value.axisLeft;
  const axisRight = productInfo.value.axisRight;
  const eye = showWitchEye.value;
  // console.log(id);
  console.log(
    `http://192.168.8.15:8080/#/prescription?productId=${id}&leftSphere=${leftSphere}&rightSphere=${rightSphere}&leftCylinder=${leftCylinder}&rightCylinder=${rightCylinder}&axisLeft=${axisLeft}&axisRight=${axisRight}&eye=${eye}`
  );
  nextTick(() => {
    const QRCodeEl = document.getElementById("QRCode");
    QRCodeEl.innerHTML = "";
    new QRCode(QRCodeEl, {
      text: `${frontendurl}/#/prescription?productId=${id}&leftSphere=${leftSphere}&rightSphere=${rightSphere}&leftCylinder=${leftCylinder}&rightCylinder=${rightCylinder}&axisLeft=${axisLeft}&axisRight=${axisRight}&eye=${eye}`,
      width: 200,
      height: 200,
    });
  });
};

// 点击下面的感叹号
const onBottomExclamationMarkClick = () => {
  showBottomTooltip.value = true;
};

const isClickOutsideBottom = useClickOutside(bottomTooltipRef);
watch(isClickOutsideBottom, () => {
  if (showBottomTooltip.value && isClickOutsideBottom.value) {
    showBottomTooltip.value = false;
  }
});

const clearButtonStyle = computed(() => {
  let style = {};
  if (clearButtonRef.value) {
    style.height = getEvenPx(clearButtonRef.value.clientHeight) + "px";
  }
  nextTick(() => {
    return style;
  });
});

const backBtnStyle = computed(() => {
  let style = {};
  if (backBtnRef.value) {
    style.height = getEvenPx(backBtnRef.value.clientHeight) + "px";
  }
  nextTick(() => {
    return style;
  });
});

onMounted(() => {
  productList.value = JSON.parse(window.sessionStorage.getItem("productInfo"));
  selectedProductId.value = JSON.parse(
    window.sessionStorage.getItem("selectedProductId")
  );

  console.log("selectedProductId.value ", selectedProductId.value);
  productInfo.value = JSON.parse(window.sessionStorage.getItem("productInfo"))[0];

  if (productList.value.length === 1) {
    showWitchEye.value = productList.value[0].eye;
  } else if (productList.value.length === 2) {
    showWitchEye.value = "All";
  }
  console.log(showWitchEye.value);

  window.sessionStorage.setItem("leftFixAxisDeg", 0);
  window.sessionStorage.setItem("rightFixAxisDeg", 0);
});
</script>

<style lang="scss" scoped>
@import "@/style/product-detail/phone.scss";
@import "@/style/product-detail/pad.scss";
</style>
